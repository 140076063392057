<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">

            <!-- Login -->
            <b-card class="mb-0">
                <div class="brand-logo">
                    <img src="@/assets/images/logo-smart-378.svg" alt="logo">
                </div>

                <b-card-title class="mb-1">
                    Pannello Admin <span class="text-primary">Smart 378</span>
                </b-card-title>
                <div v-if="!userEmail || !password || !rememberMe">
                    <b-card-text class="mb-2">
                        Accedi utilizzando le credenziali a te assegnate.
                    </b-card-text>

                    <small v-if="login_error != ''" class="text-danger">{{ login_error }}</small>

                    <!-- form -->
                    <validation-observer ref="loginForm" #default="{ invalid }">
                        <b-form class="auth-login-form mt-2" @submit.prevent>

                            <!-- email -->
                            <b-form-group label-for="email" label="Email">
                                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                                    <b-form-input id="email" v-model="userEmail" name="login-email"
                                        :state="errors.length > 0 ? false : null" placeholder="john@example.com"
                                        autofocus />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="password">Password</label>
                                </div>
                                <validation-provider #default="{ errors }" name="Password" rules="required">
                                    <b-input-group class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid' : null">
                                        <b-form-input id="password" v-model="password" :type="passwordFieldType"
                                            class="form-control-merge" :state="errors.length > 0 ? false : null"
                                            name="login-password" placeholder="Password" />

                                        <b-input-group-append is-text>
                                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility" />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                                    Ricordami
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit button -->
                            <b-button variant="primary" type="submit" block :disabled="invalid"
                                @click.prevent="loginBackend">
                                Accedi
                            </b-button>
                        </b-form>
                    </validation-observer>

                </div>

                <div v-else>

                    <p class="text-center">Bentornato, Amministratore</p>

                    <b-button class="my-2" variant="primary" type="submit" block :disabled="invalid"
                        @click.prevent="loginBackend">
                        Entra in Smart 378
                    </b-button>

                    <p class="text-center">
                        <small>
                            <b-link @click="deleteData">Esci</b-link>
                        </small>
                    </p>

                </div>
            </b-card>
            <!-- /Login v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
    BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { Requests } from '@/api/requests.js'

export default {
    components: {
        // BSV
        BButton,
        BForm,
        BFormInput,
        BFormGroup,
        BCard,
        BCardTitle,
        BLink,
        VuexyLogo,
        BCardText,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],

    created() {
        localize('it')

        this.userEmail = localStorage.getItem('admin_email');
        this.password = localStorage.getItem('admin_secret');
        this.rememberMe = localStorage.getItem('admin_remember_me');
    },

    data() {
        return {
            userEmail: '',
            password: '',
            status: '',
            login_error: '',
            rememberMe: '',
            // validation rules
            required,
            email,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },

    methods: {

        deleteData() {
            this.userEmail = '';
            this.password = '';
            this.rememberMe = null;

            localStorage.removeItem('admin_email');
            localStorage.removeItem('admin_secret');
            localStorage.removeItem('admin_remember_me');
        },


        async loginBackend() {
            this.login_error = ''

            let data = new FormData()

            data.append('admin_email', this.userEmail)
            data.append('admin_password', this.password)

            try {
                const response = await Requests.loginBackend(data);
                if (response.login.success == 'false') { this.login_error = response.login.feedback; return; }
                localStorage.setItem('admin_access_token', response.login.token);
                if (this.status) { localStorage.setItem('admin_remember_me', 1); localStorage.setItem('admin_email', this.userEmail); localStorage.setItem('admin_secret', this.password) }
                this.$router.push('/backend/dashboard');
            } catch (err) {
                console.log(err)
            }
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
